import { ImageThumbnailRatio } from '@components/ImageThumbnailRatio';
import StorySlider from '@components/StorySlider';
import VnrLink from '@components/VnrLink';
import { moneyToString } from '@utils/formatUtil';

import styles from './HaravanProduct.module.scss';

const HaravanProduct = ({ title, products }) => {
  const StoryItem = ({ product }) => {
    const thumbnail = product?.images[0]?.src;
    const variant = product?.variants[0];
    const orginalprice = variant?.compare_at_price;
    return (
      <div className={styles.storyItem} style={{ width: 206, position: 'relative' }}>
        <ImageThumbnailRatio
          src={thumbnail}
          alt={product?.title}
          ratio="256:148"
          backgroundPosition="unset"
        />
        <div className={styles.information}>
          <div className={styles.title}>{product?.title}</div>
          {product?.body_plain && <div className={styles.description}>{product?.body_plain}</div>}
        </div>
<div className={styles.priceSection}>
        {variant?.price && <div className={styles.price}>{moneyToString(variant?.price)}</div>}
        {orginalprice > 0 && <div className={styles.orginalprice}>{moneyToString(orginalprice)}</div> }
        {orginalprice > 0 && <div className={styles.discountPrice}>{((orginalprice - variant?.price) / orginalprice * 100).toFixed()}%</div>}</div>

        {product?.handle && (
          <VnrLink
            to={`https://vnrebates.store/products/${product.handle}`}
            // to="https://t.me/vnrebatessupport"
            className={styles.buttonOpenAccount}
          >
            Mua ngay
          </VnrLink>
        )}
      </div>
    );
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleSection}>{title}</div>
      <div  className={styles.productList}>
<StorySlider
        data={products.map((product) => (
          <StoryItem key={product.id} product={product} />
        ))}
        slidesToShow={2}
        className="vsaSlider"
      />
      <div className={styles.blur}></div>
      </div>
      
    </div>
  );
};
export default HaravanProduct;

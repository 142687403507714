import React from "react";
import { isMobile } from "react-device-detect";
import Slider from "react-slick";

import styles from "./StorySlider.module.scss";

const StorySlider = ({data, slidesToShow = 2, className = 'vnrb-homeTopSlider'}) => {
  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "#fff" }}
        onClick={onClick}
      />
    );
  };

  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "#fff" }}
        onClick={onClick}
      />
    );
  };

  return (
    <Slider
      dots={true}
      swipeToSlide={true}
      infinite={false}
      slidesToShow={isMobile ? 2 : slidesToShow}
      slidesToScroll={1}
      variableWidth={true}
      autoplaySpeed={4000}
      nextArrow={<NextArrow className={styles.nextArrow} />}
      prevArrow={<PrevArrow className={styles.prevArrow} />}
      className={styles[className]}
    >
      {data}
    </Slider>
  );
};

export default StorySlider;

import dayjs from 'dayjs';
var relativeTime = require('dayjs/plugin/relativeTime')
var duration = require('dayjs/plugin/duration')
dayjs.extend(duration)
dayjs.extend(relativeTime)
dayjs.locale('vi')
// import relativeTime from 'dayjs/plugin/relativeTime';
// moment.extend(relativeTime);

export const DATE_SHOW_UI = 'DD.MM.YYYY';
export const DATE_PARAM_REQ = 'YYYY-MM-DD';

export const moneyToString = (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

export const formatCurrencyVnd = (number) => new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(number);

export const stringToMoney = (value) => value.replace(/\$\s?|(,*)/g, '');

export const numberToMoney = (value) =>
  value ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0';

export const formatMoneyWithFixedPoint = (value, digit = 2) =>
  moneyToString(Number(value.toFixed(digit)));

export const dateToString = (date, format = DATE_SHOW_UI) => dayjs(date).format(format);

export const dateToDate = (date) => dayjs(date).toDate();

export const dateFromNow = (date, format = DATE_SHOW_UI) => dayjs(date).fromNow();

export const dateToStringParamReq = (date) => {
  const dateDayjs = dayjs(date);
  return dateDayjs.isValid() ? dateDayjs.format(DATE_PARAM_REQ) : '';
};

export const stringParamReqToDate = (param) => {
  const dateDayjs = dayjs(param, DATE_PARAM_REQ);
  return dateDayjs.isValid() ? dateDayjs : null;
};

export const roundUpDouble = (num, n = 2) => {
  if (!num) {
    return 0;
  }
  const x = 10 ** n;
  return Math.round(num * x) / x;
};

export const blobToBase64 = (blob, callback) => {
  var reader = new FileReader();
  reader.onload = function () {
    var dataUrl = reader.result;
    var base64 = dataUrl.split(',')[1];
    callback(base64);
  };
  reader.readAsDataURL(blob);
};

export const getPublishDate = (publishTimeStr, timeAdd = 7) => {
  const formatPublishTimeStr = dayjs(publishTimeStr).format('DD-MM-YYYY HH:mm:ss');
  const startPublishTime = dayjs(formatPublishTimeStr).add(timeAdd, 'hour');
  const currentTime = dayjs();
  if (currentTime.diff(startPublishTime, 'hour') < 24) {
    return startPublishTime.fromNow();
  } else {
    return startPublishTime.format('DD.MM.YYYY');
  }
};

export const getModifiedDate = (modifiedTimeStr) => {
  const startModifiedTime = dayjs(modifiedTimeStr);
  const currentTime = dayjs();

  let getMinFromNow = currentTime.diff(startModifiedTime, 'minute');
  if (getMinFromNow < 1) getMinFromNow = 1;

  if (getMinFromNow < 60) {
    return `${getMinFromNow}m trước`;
  }

  const getHourFromNow = currentTime.diff(startModifiedTime, 'hour');
  if (getHourFromNow < 24) {
    return `${getHourFromNow}h trước`;
  } else {
    return startModifiedTime.format('DD.MM.YYYY');
  }
};

export const countDateFromNow = (date) => {
  return dayjs.duration(dayjs().diff(dayjs(date))).asDays();
}

export const countDateFromStartDateToEndDate = (startDate, endDate) => {
  return dayjs.duration(dayjs(startDate).diff(dayjs(endDate))).asDays();
}
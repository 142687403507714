import { ImageThumbnailRatio } from '@components/ImageThumbnailRatio';
import VnrLink from '@components/VnrLink';

import { landingPageUrlList } from './NewPostGroup';
import styles from './PostGroup.module.scss';

const SmallPostItem = ({ item }) => {

  const isLandingPage = landingPageUrlList.includes(item.slug);
  return (
    <div className={styles.latestPostWrapper}>
      <VnrLink to={isLandingPage ? item.slug : `${item.slug}.html`} className={styles.smallPostItem}>
        <div className={styles.smallPostItemThumbnail}>
          <ImageThumbnailRatio src={item.thumbnail} ratio="16:9" alt="thumbnail" />
        </div>
        <div className={styles.smallPostItemTitle}
          dangerouslySetInnerHTML={{__html: item?.title?.rendered}}
        ></div>
      </VnrLink>
    </div>
  );
};
export default SmallPostItem;

import Image from 'next/image';
import React from 'react';

const BannerHome = () => {
  return (
    <Image
      width={770}
      height={390}
      alt="HomeBanner"
      quality={100}
      src="/images/vipSignal.png"
    ></Image>
  );
};

export default BannerHome;

import React from 'react';
import { useCountdown } from 'src/hook/useCountdown';

import styles from './AirdropCountdown.module.scss'
import { ClockCircleOutlined } from '@ant-design/icons';
const ShowCounter = ({ days, hours, minutes, seconds }) => {
  const modifiedValue = (value) => {
    if (!value) return '00';
    if (value < 10) return '0' + value; 
    return value;
  }
  return (
    <div className={styles.counter}>
      <div className={styles.time}>{modifiedValue(days)}</div>
      <div className={styles.time}>{modifiedValue(hours)}</div>
      <div className={styles.time}>{modifiedValue(minutes)}</div>
      <div className={styles.time}>{modifiedValue(seconds)}</div>
    </div>
  );
};

const AirdropCountdown = ({ targetDate }) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  if (days + hours + minutes + seconds <= 0) {
    return <div className={styles.ended}>Đã kết thúc</div>;
  } else {
    return (
    <div className={styles.airdropCountdown}>
      <div className={styles.title}>AIRDROP</div>
      <div className={styles.endIn}>
        <ClockCircleOutlined />
        <span>KẾT THÚC TRONG</span></div>
      <ShowCounter days={days} hours={hours} minutes={minutes} seconds={seconds} />
      </div>)
  }
};

export default AirdropCountdown;

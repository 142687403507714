export const getByKey = (obj, key) => {
  try {
    return obj[key];
  } catch (err) {
    return null;
  }
};

export const containsCategories = (arr1, arr2) => {
  return arr1.every(item => arr2.includes(item));
}
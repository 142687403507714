const fs = require("fs");
const path = require("path");


const cacheDir = "cache-data";

const cache = {};

const toObj = (source, key) => {
  return source.reduce((obj, item) => {
    obj[item[key]] = item;
    return obj;
  }, {});
};

const cacheAllData = async ({ fileName, service, groupKeys =[] }) => {
  if (cache[fileName]) {
    return cache[fileName];
  }
  if (!fs.existsSync(cacheDir)) {
    fs.mkdirSync(cacheDir);
  }
  const cachePath = path.join(path.resolve(cacheDir,   fileName + ".json"));
  const getCachedData = () => {
    let cachedData;
    try {
      cachedData = JSON.parse(fs.readFileSync(cachePath, "utf8"));
    } catch (error) {
      console.error("cache not initialized");
    }
    return cachedData;
  };

  // read cache
  let cachedData = getCachedData();
  if (cachedData) {
    const result = { data: { content: cachedData } };
    cache[fileName] = result;
    return result;
  }

  console.info("call service...", fileName);
  let dataService = await service();
  let dataGroup = {};
  if (groupKeys && groupKeys.length > 0) {
    groupKeys.forEach(key => {
      dataGroup[key] = toObj(dataService, key)
    });
    dataService = dataGroup;
  }
  try {
    fs.writeFileSync(cachePath, JSON.stringify(dataService), "utf8");
    console.info("Wrote to data cache");
  } catch (error) {
    console.error("ERROR WRITING DATA CACHE TO FILE", error);
  }
  return { data: { content: dataService } };
};


module.exports =  cacheAllData ;

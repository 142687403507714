
const IconClock = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.6667 7.99998C14.6667 11.68 11.68 14.6666 7.99999 14.6666C4.31999 14.6666 1.33333 11.68 1.33333 7.99998C1.33333 4.31998 4.31999 1.33331 7.99999 1.33331C11.68 1.33331 14.6667 4.31998 14.6667 7.99998Z" fill="white" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M10.4733 10.12L8.40666 8.88665C8.04666 8.67332 7.75333 8.15999 7.75333 7.73999V5.00665" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>


  );
};
export default IconClock;

import dataCategories from '@services/categories';

import cacheAllData from './cacheService';
import { containsCategories, getByKey } from './wpUtils';

export const getDataCache = async () => {
  const cachePosts = await cacheAllData({
    fileName: 'post_data',
  });
  return cachePosts;
};

export const getAllPosts = async () => {
  const data = await getDataCache();
  return { data: { content: Object.values(data.data.content.id) } };
};

export const getPostById = async (id) => {
  const cachePosts = await getDataCache();
  const post = getByKey(cachePosts.data.content.id, id + '');
  return { data: { content: post } };
};

export const getPostByIds = async (ids = []) => {
  const cachePosts = await getDataCache();
  const posts = ids
    .map((id) => getByKey(cachePosts.data.content.id, id + ''))
    .filter((item) => item);
  return posts;
};

export const getWpPostsCacheByAuthorId = async (authorId) => {
  const cachePosts = await getDataCache();
  const post = Object.values(cachePosts.data.content.id).filter((item) => item?.author?.id == authorId);
  return { data: { content: post } };
};

export const getPostByCategories = async ({
  categories = [],
  size = 5,
} = {}) => {
  let categoryIds = dataCategories
    .filter((cate) => categories.includes(cate.slug))
    .map((cate) => cate.id);

  const cachePosts = await getAllPosts();
  let posts = cachePosts.data.content
    .sort((a, b) => b.date.localeCompare(a.date))
    .filter((post) => categoryIds.every((cate) => post.categories.includes(cate)));

  if (size != -1 && size < posts.length) {
    posts = posts.slice(0, size);
  }

  return { data: { content: posts } };
};

export const getPostInCategories = async ({
  categories = [],
  // page = 0,
  size = 5,
} = {}) => {
  let categoryIds = dataCategories
    .filter((cate) => categories.includes(cate.slug))
    .map((cate) => cate.id);
  categoryIds.join(',');

  const cachePosts = await getAllPosts();
  let posts = [];

  cachePosts.data.content
    .sort((a, b) => b.date.localeCompare(a.date))
    .every((post) => {
      if (posts.length > size) return false;

      if (categoryIds.some((cate) => post.categories.includes(cate))) {
        posts.push(post);
      }

      return true;
    });

  return { data: { content: posts } };
};

export const getPostByCategoryIds = async ({
  categories = [],
  // page = 0,
  size = 5,
} = {}) => {
  let categoryIds = dataCategories
    .filter((cate) => categories.includes(cate.id))
    .map((cate) => cate.id);
  categoryIds.join(',');

  const cachePosts = await getAllPosts();
  let posts = cachePosts.data.content
    .sort((a, b) => b.date.localeCompare(a.date))
    .filter((post) => categoryIds.every((cate) => post.categories.includes(cate)));

  if (size != -1 && size < posts.length) {
    posts = posts.slice(0, size);
  }

  return { data: { content: posts } };
};

export const getPostByExcludeCategories = async ({
  categories = [],
  // page = 0,
  size = 5,
} = {}) => {
  let categoryIds = dataCategories
    .filter((cate) => categories.includes(cate.slug))
    .map((cate) => cate.id);
  categoryIds.join(',');

  const cachePosts = await getAllPosts();
  let posts = cachePosts.data.content
    .sort((a, b) => b.date.localeCompare(a.date))
    .filter((post) => categoryIds.every((cate) => !post.categories.includes(cate)));

  if (size != -1 && size < posts.length) {
    posts = posts.slice(0, size);
  }

  return { data: { content: posts } };
};

export const getPostsByBrokerTag = async (slug) => {
  const cachePosts = await getAllPosts();
  const posts = cachePosts.data.content.filter(
    (post) => post.acf.target_broker && post?.acf?.target_broker?.slug === slug
  );
  
  return posts;
};

export const getPostWpIdByUrl = async (url) => {
  const cachePosts = await getAllPosts();
  const post = cachePosts.data.content.find((p) => p.link === url);

  return post.id;
};

export const getPostInAndExcludeCategories = async ({
  categories = [],
  excludeCategories = [],
  // page = 0,
  size = 5,
} = {}) => {
  let categoryIds = dataCategories
    .filter((cate) => categories.includes(cate.slug))
    .map((cate) => cate.id);
  categoryIds.join(',');

  let excludeCategoryIds = dataCategories
    .filter((cate) => excludeCategories.includes(cate.slug))
    .map((cate) => cate.id);
  excludeCategoryIds.join(',');

  const cachePosts = await getAllPosts();
  let posts = [];

  cachePosts.data.content
    .sort((a, b) => b.date.localeCompare(a.date))
    .every((post) => {
      if (posts.length > size) return false;

      if (
        !excludeCategoryIds.some((cate) => post.categories.includes(cate)) &&
        categoryIds.some((cate) => post.categories.includes(cate))
      ) {
        posts.push(post);
      }

      return true;
    });

  return { data: { content: posts } };
};

export const getPostHaveCategoriesAndSize = async ({ categories = [], size = 5 } = {}) => {
  if (categories.length === 0) return [];

  const cachePosts = await getAllPosts();
  let posts = [];

  cachePosts.data.content
    .sort((a, b) => b.date.localeCompare(a.date))
    .every((post) => {
      if (posts.length === size) return false;

      if (containsCategories(categories, post.categories)) {
        posts.push(post);
      }

      return true;
    });

  return posts;
};

export const getPostInCategoriesAndSize = async ({
  categories = [],
  size = 5,
} = {}) => {
  if (categories.length === 0) return [];

  const cachePosts = await getAllPosts();
  let posts = [];

  cachePosts.data.content
    .sort((a, b) => b.date.localeCompare(a.date))
    .every((post) => {
      if (posts.length === size) return false;

      if (categories.some((cate) => post.categories.includes(cate))) {
        posts.push(post);
      }

      return true;
    });

  return posts;
};

export const getPostSlugByCategories = async ({
  categories = [],
  // page = 0,
  size = 5,
} = {}) => {
  let categoryIds = dataCategories
    .filter((cate) => categories.includes(cate.slug))
    .map((cate) => cate.id);
  // categoryIds.join(",");

  const cachePosts = await getDataCache();
  const slugs = Object.values(cachePosts.data.content.id)
    // .sort((a, b) => b.date.localeCompare(a.date))
    .filter((post) => categoryIds.every((cate) => post.categories.includes(cate)))
    .map((item) => {
      return {
        slug: item.slug,
        acf: {
          attr_no_index: item?.acf?.attr_no_index,
        },
      };
    });

  if (size !== -1 && size < slugs.length) {
    return slugs.slice(0, size);
  }

  return slugs;
};

export const getPostByWpIds = async (ids = []) => {
  const cachePosts = await getDataCache();
  const cachePost = cachePosts.data.content.id;
  const posts = ids
    .map((id) => {
      const post = Object.values(cachePost).find(item => item.wpPostId == id);

      return post;
    })
    .filter(Boolean);
  return posts;
};
import { ImageThumbnailRatio } from '@components/ImageThumbnailRatio';
import { useModal } from '@components/ModalHook';
import StorySlider from '@components/StorySlider';
import TitleSection from '@components/TitleSection';
import { getStory } from '@services/story.service';
import { getModifiedDate } from '@utils/formatUtil';
import React, { useEffect, useState } from 'react';

import IconClock from './IconClock';
import ModalStory, { MODAL_ID as MODAL_STORY } from './ModalStory';
import styles from './Story.module.scss';

const Story = () => {
  const [, showModalStory] = useModal(MODAL_STORY);
  const [stories, setStories] = useState([]);

  const callGetStory = async () => {
    const res = await getStory({ page: 0, size: 10 });

    if (res?.data?.content) {
      const storyList = res.data.content.map((item, index) => {
        item.order = index;
        return item;
      });
      setStories(storyList);
    }
    return;
  };

  const StoryItem = ({ story }) => {
    const time = getModifiedDate(story?.createdDate);

    return (
      <div
        className={styles.storyItem}
        style={{ width: 206, position: 'relative' }}
        onClick={() => showModalStory({ content: story })}
      >
        <ImageThumbnailRatio
          src={story?.imageUrl?.replace(
            's3-ap-southeast-1.amazonaws.com/agileops-gafv-dev-videos',
            'd3gxgptuxgz1ri.cloudfront.net'
          )}
          alt={story?.title}
          ratio="206:300"
          backgroundPosition="unset"
        >
          <div className={styles.time}>
            <IconClock />
            <span>{time}</span>
          </div>

          <div className={styles.information}>
            <div className={styles.title}>{story?.title}</div>
            <div className={styles.description}>{story?.description}</div>
          </div>
        </ImageThumbnailRatio>
      </div>
    );
  };

  useEffect(() => {
    callGetStory();
  }, []);

  return (
    <div className={styles.vnrYoutube}>
      <TitleSection title="Short video" isMore={false} />
      <StorySlider
        data={stories.map((story) => (
          <StoryItem key={story.id} story={story} />
        ))}
        slidesToShow={4}
        className="vsaSlider"
      />
      <ModalStory stories={stories} />
    </div>
  );
};
export default Story;

import { ImageThumbnailRatio } from '@components/ImageThumbnailRatio';
import ModalHook, { useModal } from '@components/ModalHook';
import React, { useEffect,useState } from 'react';
import ReactPlayer from 'react-player';
import Slider from "react-slick";

import styles from './Story.module.scss';
import VsaArrowIcon from './VsaArrowIcon';

export const MODAL_ID = 'MODAL_STORY';

const ModalStory = ({stories}) => {
  const [modalData, , hideModal] = useModal(MODAL_ID);
  const [story, setStory] = useState();
  const [isPlay, setIsPlay] = useState(false);

  let refSlider = null;

  const onClickNext = () => {
    const nextStory = stories.filter((item) => item.order < story?.order).at(-1);
    if (!nextStory) return;
    setStory(nextStory)
    refSlider?.slickGoTo(nextStory.order);
  };

  const onClickPrev = () => {
    const prevStory = stories.filter((item) => item.order > story?.order)[0];
    if (!prevStory) return;

    setStory(prevStory);
    refSlider?.slickGoTo(prevStory.order);
  };

  const keydownFunction = (event) => {
    if (event.code === 'ArrowRight') {
      onClickPrev()
    }
    if (event.code === 'ArrowLeft') {
      onClickNext()
    }
  };

  const onSlickChange = (index) => {
    
    const videoId = stories[index];
    if (videoId) {
      setIsPlay(true)
      setStory(videoId)
    };
  };

  useEffect(() => {
    const storyModal = modalData?.data?.content;
    
    if (!storyModal) {
      setIsPlay(false);

      return;
    }
    const initStory = stories.find(item => item.id === storyModal?.id);
    
    if (storyModal) {
      setIsPlay(true)
      refSlider.slickGoTo(initStory.order);
    }
    setStory(storyModal);
  }, [modalData]);

  useEffect(() => {
    if (!story) return;

    if (typeof window === 'undefined') return;
    window.addEventListener('keydown', keydownFunction);

    return () => {
      window.removeEventListener('keydown', keydownFunction);
    };
  }, [story]);

  const onCancel = () => {
    setIsPlay(false)
    setTimeout(() => {
      hideModal();
    }, 100)
    
  }
  return (
    <ModalHook
      title={null}
      onCancel={onCancel}
      id={MODAL_ID}
      footer={null}
      width={440}
      wrapClassName={styles.modalCreateResume}
      maskStyle={{backgroundColor: "#1E1E1E"}}
      maskClosable={true}
    >
      <div id="play-button" className={styles.storyContentWrapper}>
        {story?.videoId 
          ? <ReactPlayer
              url={`https://www.youtube.com/watch?v=${story?.videoId}`}
              width="100%"
              height="100vh"
              playing={isPlay}
              controls={false}
            /> 
          : <ImageThumbnailRatio
              src={story?.imageUrl}
              alt={story?.title} 
              ratio="440:780"
              backgroundPosition='unset'
            />
        }

        <div className={styles.informationModal}>
          <div className={styles.title}>
            {story?.title}
          </div>
          <div className={styles.descriptionModal}>
            {story?.description}
          </div>
        </div>
      </div>
      <Slider
        ref={(slider) => (refSlider = slider)}
        dots={true}
        infinite={false}
        swipe={true}
        slidesToShow={1}
        slidesToScroll={1}
        autoplaySpeed={4000}
        cssEase='linear'
        className={styles.vsaSlider}
        lazyLoad="ondemand"
        afterChange={(slick) => onSlickChange(slick)}
      >
        {stories.map((item) => <div key={item.order}></div>)}
      </Slider>
      {!story?.isFirst &&
        <div 
          className={styles.nextButton}
          onClick={onClickNext}
        >
          <VsaArrowIcon />
        </div>
      }
      {!story?.isLast &&
        <div 
          className={styles.prevButton}
          onClick={onClickPrev}
        >
          <VsaArrowIcon />
        </div>
      }
    </ModalHook>
  );
};
export default ModalStory;
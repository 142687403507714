import VnrLink from "@components/VnrLink";
import React from "react";
import useTranslate from "src/hook/useTranslate";

import styles from "./TitleSection.module.scss";

const TitleSection = ({ title, isMore = true, link, type, isPostGroupForex = false }) => {
  const translate = useTranslate();
  
  return (
    <div className={styles.vnrTitle}>
      <h2
        className={isPostGroupForex ? styles.forexTitle : styles.title}
        // style={type === "banner" ? { fontSize: 18 } : {}}
        style={type === "brokerList" ? { color: "#ee7938" } : {}}
      >
        {title}
      </h2>
      {isMore && (
        <VnrLink to={link} className={styles.readMore}>
          &#187; {translate?.homepageBrokerList?.viewMore || 'Xem tất cả'}
        </VnrLink>
      )}
    </div>
  );
};
export default TitleSection;
